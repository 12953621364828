import { render, staticRenderFns } from "./truckTitleCell.vue?vue&type=template&id=7b08b579&scoped=true"
import script from "./truckTitleCell.vue?vue&type=script&lang=js"
export * from "./truckTitleCell.vue?vue&type=script&lang=js"
import style0 from "./truckTitleCell.vue?vue&type=style&index=0&id=7b08b579&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b08b579",
  null
  
)

export default component.exports
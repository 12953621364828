<template>
  <h5 class="mb-2">
    <slot />
  </h5>
</template>
<script>
export default {
  name: 'DriverFormBlockTitle',
}
</script>

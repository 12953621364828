import { render, staticRenderFns } from "./regions.vue?vue&type=template&id=dae4f1c8&scoped=true"
import script from "./regions.vue?vue&type=script&lang=js"
export * from "./regions.vue?vue&type=script&lang=js"
import style0 from "./regions.vue?vue&type=style&index=0&id=dae4f1c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae4f1c8",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./fineForm.vue?vue&type=template&id=f71f1b74"
import script from "./fineForm.vue?vue&type=script&lang=js"
export * from "./fineForm.vue?vue&type=script&lang=js"
import style0 from "./fineForm.vue?vue&type=style&index=0&id=f71f1b74&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
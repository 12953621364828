<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <v-progress-circular
              :size="90"
              :width="5"
              color="primary"
              indeterminate
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {}
</script>
<style></style>

import { render, staticRenderFns } from "./placesForTransferDocs.vue?vue&type=template&id=470965fe&scoped=true"
import script from "./placesForTransferDocs.vue?vue&type=script&lang=js"
export * from "./placesForTransferDocs.vue?vue&type=script&lang=js"
import style0 from "./placesForTransferDocs.vue?vue&type=style&index=0&id=470965fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470965fe",
  null
  
)

export default component.exports
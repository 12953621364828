<template>
  <v-btn>
    <v-icon> mdi-cached </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'refreshBtn',
}
</script>

<style></style>
